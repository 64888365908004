@media screen and (min-width: 768px) {
  .slide-group-1 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2023/11/24-na-tour-background.jpg");
  }
}

@media screen and (max-width: 767px) {
  .slide-group-1 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2023/11/24-na-tour-background-mobile-750x1624.jpg");
  }
}
:hover {
  background-color: fb4656 !important;
}
:hover {
  background-color: c38f06 !important;
}
:hover {
  background-color: c71218 !important;
}
:hover {
  background-color: eadeb2 !important;
}

@media screen and (min-width: 768px) {
  .slide-group-2 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2023/11/rs-crossfire-hurricane-cover.jpg");
  }
}

@media screen and (max-width: 767px) {
  .slide-group-2 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2023/11/rs-crossfire-hurricane-background-mobile.jpg");
  }
}

@media screen and (min-width: 768px) {
  .slide-group-3 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2023/09/rs-hd-carousel-bg@2x-1920x1080.jpg");
  }
}

@media screen and (max-width: 767px) {
  .slide-group-3 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2023/09/rs-hd-carousel-bg@2x-1920x1080.jpg");
  }
}

@media screen and (min-width: 768px) {
  .slide-group-5 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2020/08/rs-store-background-1-1920x1080.jpg");
  }
}

@media screen and (max-width: 767px) {
  .slide-group-5 {
    background-image: url("https://rollingstones.com/wp-content/uploads/2020/08/rs-store-background-1-1920x1080.jpg");
  }
}
